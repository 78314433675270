import firebase from 'firebase'

  // Your web app's Firebase configuration
let config = {
  apiKey: "AIzaSyCDwlLgJX1fSzzJ3fVWYQd4CezJHmtrgTU",
  authDomain: "weprep-signups.firebaseapp.com",
  databaseURL: "https://weprep-signups.firebaseio.com",
  projectId: "weprep-signups",
  storageBucket: "weprep-signups.appspot.com",
  messagingSenderId: "1074291762595",
  appId: "1:1074291762595:web:1a9f039e27d946b00c6616",
  measurementId: "G-3WEQ7JMW80"
};
// Initialize Firebase
firebase.initializeApp(config);
// const facebookProvider = new firebase.auth.FacebookAuthProvider()

const storage = firebase.storage()
export {
  storage, firebase as default
}
