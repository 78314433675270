import React from 'react';
// import logo from './logo.svg';
import './App.css';
import './responsive.css';
import firebase from './config.js'
import logo from "./images/prepmetoplogo.gif"
import { Button, Form, FormGroup, Input, Modal, ModalHeader, ModalBody, ModalFooter  } from 'reactstrap';
import { Picture } from 'react-responsive-picture';


const width = window.innerWidth

class App extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      email: "",
      modalVisible: false
    }


  }
  //https://script.google.com/macros/s/AKfycbxsRG5IDmpfIBcHm4semWcmgiAxiFD9yNN5ZvZGAilInnFX8hg/exec

  handleChange = (event) => {
    this.setState({email: event.target.value});
  }

  onSubmit = (event) => {
    event.preventDefault()
    if(this.state.email !== ""){
      firebase.database().ref("emails").push(this.state.email)
      this.setState({email: "", modalVisible: true})
    }
  }

  toggle = () => {
    this.setState({modalVisible: !this.state.modalVisible})
  }

  render(){
  return (
    <div style = {{height: "100vh", backgroundColor: "white"}}>
        {width > 850 ?
          <div>
      <div style = {{margin: "5vh", marginBottom: "0px", marginTop: "1vh", alignSelf: "center", textAlign: "center"}}>

          <div style={{width: 500}} className="responsive-image">
          <div style={{paddingBottom: ( 140 / 500 * 100 ) + '%'}}/>
            <img src = {logo} alt="logo" className = "responsive-image__image"/>
          </div>
      </div>


        <div style = {{display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "5vh"}}>
        <div style = {{width: "50%", margin: "5vh", marginBottom: "0px", marginTop: "0px"}}>
            <h5 style = {{fontFamily: "Futura", textAlign: "center"}}>WHO WE ARE</h5>
            <p style = {{fontFamily: "Futura", color: "gray", fontWeight: "500"}}>
              Andres Balcazar is are the founder and creator of wePrep. Andres is an Industrial Engineering major pursing the Kellogg Managerial Analytics certificate at Northwestern University.
            </p>
        </div>
        <div style = {{width: "50%", margin: "5vh", marginBottom: "0px", marginTop: "0px"}}>
            <h5 style = {{fontFamily: "Futura", textAlign: "center"}}>WHAT WE DO</h5>
            <p style = {{fontFamily: "Futura", color: "gray", fontWeight: "500"}}>
              Andres noticed a huge problem with the job interview preparation process which wePrep seeks to solve. WePrep is an application that allows users to easily connect for the purpose of practicing and preparing for consulting interviews. WePrep makes it easy for users to study with others and/or schedule tutoring sessions with offer-holding students and professional consultants.
            </p>
        </div>

        <div style = {{width: "50%", margin: "5vh", marginTop: "0vh", marginBottom: "0vh",}}>
            <h5 style = {{fontFamily: "Futura", textAlign: "center"}}>WHY US</h5>
            <p style = {{fontFamily: "Futura", color: "gray", fontWeight: "500"}}>
              We want to provide people of all backgrounds the opportunity to prepare for interviews without limits. Anyone can download the app for free to explore, and the cost to use the platform&apos;s unlimited "match and prep" function is only $9.99. The first one hundred subscribers will be able to use the basic platform for free!
            </p>
        </div>

        </div>

        <div style = {{margin: "5vh", marginTop: "5vh", marginBottom: "2vh", textAlign: "center"}}>
          <h5 style = {{fontFamily: "Futura"}}>OUR MISSION IS TO HELP YOU</h5>
            <p style = {{fontFamily: "Futura", color: "gray", fontWeight: "500"}}>
              Ace your interview and land your dream job!
            </p>
        </div>


        <div style = {{ margin: "5vh", marginTop: "30px", alignSelf: "center", textAlign: "center"}}>
          <Form style = {{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
            <FormGroup style = {{width: "60%", alignSelf: "center"}} >
              <Input type="email" name="email" id="exampleEmail" placeholder="Enter your Email to Subscribe for Updates!" value={this.state.email} onChange={this.handleChange} />
            </FormGroup>
            <Button style = {{width: "50%", backgroundColor: "#04d68a", border: "0px"}} onClick = {this.onSubmit}>Submit</Button>
          </Form>
        </div>

        <div style = {{ margin: "5vh", marginTop: "0vh", marginBottom: "0vh", textAlign: "center"}}>
            <p style = {{fontFamily: "Futura", color: "gray", fontWeight: "500"}}>
              First 100 subscribers can use wePrep basic for free!
            </p>
        </div>
        </div>
        :


        <div>
    <div style = {{margin: "5vh", marginBottom: "0px", marginTop: "1vh", alignSelf: "center", textAlign: "center"}}>

        <div style={{width: 500}} className="responsive-image">
        <div style={{paddingBottom: ( 140 / 500 * 100 ) + '%'}}/>
          <img src = {logo} alt="logo" className = "responsive-image__image"/>
        </div>
    </div>


      <div style = {{display: "flex", flexDirection: "column", justifyContent: "center", marginTop: "5vh"}}>

      <div style = {{margin: "5vh", marginBottom: "0px", marginTop: "0px"}}>
          <h5 style = {{fontFamily: "Futura", textAlign: "center"}}>WHO WE ARE</h5>
          <p style = {{fontFamily: "Futura", color: "gray", fontWeight: "500"}}>
                        Andres Balcazar is the founder and creator of wePrep. Andres is an Industrial Engineering major pursing the Kellogg Managerial Analytics certificate at Northwestern University.
          </p>
      </div>

      <div style = {{margin: "5vh", marginBottom: "0px", marginTop: "0px"}}>
          <h5 style = {{fontFamily: "Futura", textAlign: "center"}}>WHAT WE DO</h5>
          <p style = {{fontFamily: "Futura", color: "gray", fontWeight: "500"}}>
          Andres noticed a huge problem with the job interview preparation process which wePrep seeks to solve. WePrep is an application that allows users to easily connect for the purpose of practicing and preparing for consulting interviews. WePrep makes it easy for users to study with others and/or schedule tutoring sessions with offer-holding students and professional consultants.
          </p>
      </div>

      <div style = {{margin: "5vh", marginTop: "0vh", marginBottom: "0vh",}}>
          <h5 style = {{fontFamily: "Futura", textAlign: "center"}}>WHY US</h5>
          <p style = {{fontFamily: "Futura", color: "gray", fontWeight: "500"}}>
            We want to provide people of all backgrounds the opportunity to prepare for interviews without limits. Anyone can download the app for free to explore, and the cost to use the platform&apos;s unlimited "match and prep" function is only $9.99. The first one hundred subscribers will be able to use the basic platform for free!
          </p>
      </div>

      </div>

      <div style = {{margin: "5vh", marginTop: "5vh", marginBottom: "2vh", textAlign: "center"}}>
        <h5 style = {{fontFamily: "Futura"}}>OUR MISSION IS TO HELP YOU</h5>
          <p style = {{fontFamily: "Futura", color: "gray", fontWeight: "500"}}>
            Ace your interview and land your dream job!
          </p>
      </div>


      <div style = {{ margin: "5vh", marginTop: "30px", alignSelf: "center", textAlign: "center"}}>
        <Form style = {{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
          <FormGroup style = {{width: "90%", alignSelf: "center"}} >
            <Input type="email" name="email" id="exampleEmail" placeholder="Enter your Email to Subscribe for Updates!" value={this.state.email} onChange={this.handleChange} />
          </FormGroup>
          <Button style = {{width: "50%", backgroundColor: "#04d68a", border: "0px"}} onClick = {this.onSubmit}>Submit</Button>
        </Form>
      </div>

      <div style = {{ margin: "5vh", marginTop: "0vh", marginBottom: "0vh", textAlign: "center"}}>
          <p style = {{fontFamily: "Futura", color: "gray", fontWeight: "500"}}>
            First 100 subscribers can use wePrep basic for free!
          </p>
      </div>
      </div>

         }

         <Modal isOpen={this.state.modalVisible} toggle = {this.toggle}>
           <ModalHeader toggle={this.toggle}>Thank You!</ModalHeader>
           <ModalBody>
             Thank you for signing up! We will send you updates on wePrep&apos;s development progress and promotions.
           </ModalBody>
           <ModalFooter>
             <Button color="primary" onClick={this.toggle}>Close</Button>{' '}
           </ModalFooter>
         </Modal>


    </div>
  );
  }
}

export default App;
